import React, {useState} from "react";
import styles from './Newsletter.module.scss';

const Newsletter = ({title, text, placeholder, btnText}) => {
    const [value, setValue] = useState('');

    return (
        <div className={styles.newsletter}>
            <h3>{title}</h3>
            <p className={styles.newsletter__text}>{text}</p>
            <div className={styles.newsletter__action}>
                <input type="text" placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} />
                <button className={'blueBtn'}>
                    <span>{btnText}</span>
                </button>
            </div>
        </div>
    )
}

export default Newsletter;