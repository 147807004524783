import React from "react";
import styles from './Main.module.scss';
import {Route, Routes} from "react-router-dom";
import MainContent from "./MainContent/MainContent";
import Deposit from "./Deposit/Deposit";
import Withdraw from "./Withdraw/Withdraw";
import Transfer from "./Transfer/Transfer";
import WalletConnect from "./WalletConnect/WalletConnect";
import History from "./History/History";


const Main = () => {

    return (
        <div className={styles.main}>
            <Routes>
                <Route index element={<MainContent />} />
                <Route path={'deposit/*'} element={<Deposit />} />
                <Route path={'deposit/:coin/*'} element={<Deposit />} />
                <Route path={'withdraw/*'} element={<Withdraw />} />
                <Route path={'withdraw/:coin/*'} element={<Withdraw />} />
                <Route path={'transfer/*'} element={<Transfer />} />
                <Route path={'history/*'} element={<History />} />
                <Route path={'wallet_connect/*'} element={<WalletConnect />} />
            </Routes>
        </div>
    )
}

export default Main;