import React, {useEffect, useState} from "react";
import styles from './Spot.module.scss';
import SpotOrderForm from "./SpotOrderForm/SpotOrderForm";
import SpotHeader from "./SpotHeader/SpotHeader";
import SpotFooter from "./SpotFooter/SpotFooter";
import {useAppSelector} from "../../../../hooks/redux";
import {getIsDarkTheme, getText} from "../../../../store/selectors";
import OrderRecentWrapper from "./OrderRecentWrapper/OrderRecentWrapper";
import TradingViewWidget from "./TradingViewWidget";
import {Preloader} from "../../../Common/Preloader/Preloader";
import SpotChangeContent from "./SpotChangeContent";
import SpotTotalChangerWrapper from "./SpotHeader/SpotTotalChangerWrapper/SpotTotalChangerWrapper";
import SpotTransfer from "./SpotTransfer/SpotTransfer";
import arrow from "../../../../assets/icons/selectarrow.svg";

const Spot = ({isAuth = true}) => {
    const isDark = useAppSelector(getIsDarkTheme);
    const {spot} = useAppSelector(getText);
    const [popupType, setPopupType] = useState(null);
    const [coinPrice, setCoinPrice] = useState(0);
    const [pair, setPair] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLeftContent, setIsLeftContent] = useState('schedule');
    const [showContent, setShowContent] = useState(false);
    const [allTrades, setAllTrades] = useState([]);
    const [buy, setBuy] = useState([]);
    const [sell, setSell] = useState([]);
    const [balance, setBalance] = useState(null);
    const [secondBalance, setSecondBalance] = useState(null);
    const [operation, setOperation] = useState('Buy');
    const [trading, setTrading] = useState(spot.spotTrading);

    useEffect(() => {
        setTrading(spot.spotTrading)
    }, [spot])


    const changePair = (value) => {
        setIsLoading(true);
        setTimeout(() => {
            setPair(value);
            setIsLoading(false);
        }, 800)
    }

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 800)
    }, [isDark])

    return (
        <div className={isDark ? `${styles.spot} spot dark` : `${styles.spot} spot light`}>
            {window.innerWidth < 743 && (
                <SpotChangeContent isLeftContent={isLeftContent} setIsLeftContent={setIsLeftContent} isDark={isDark} text={spot}/>
            )}
            <div className={styles.mainContent}>
                {(window.innerWidth < 743 && isLeftContent === 'schedule') || window.innerWidth >= 743 ? (
                    <div className={styles.leftBox}>
                        <SpotHeader text={spot} pair={pair} changePair={changePair} setCoinPrice={setCoinPrice}
                                    coinPrice={coinPrice}/>
                        <div className={styles.leftBox__content}>
                            <div className={styles.chartWrapper}>
                                {pair && !isLoading
                                    ? (
                                        <TradingViewWidget pair={pair} isDark={isDark}/>
                                    )
                                    : <div className='loadingLocal' style={{backgroundColor: 'transparent'}}>
                                        <Preloader/>
                                    </div>
                                }
                                {window.innerWidth < 743 && pair && coinPrice && (
                                    <div className={styles.mobActions}>
                                        <button className={styles.mobActions__buy} onClick={() => {
                                            setOperation('Buy');
                                            setIsLeftContent('spot')
                                        }}>
                                            <span>{spot.buy}</span>
                                        </button>
                                        <button className={styles.mobActions__sell} onClick={() => {
                                            setOperation('Sell');
                                            setIsLeftContent('spot')
                                        }}>
                                            <span>{spot.sell}</span>
                                        </button>
                                    </div>
                                )}

                            </div>
                            {window.innerWidth >= 1280 && window.innerWidth <= 1920 && (
                                <div className={`${styles.spotBookWrapper} spotBg`}>
                                    <OrderRecentWrapper text={spot} isDark={isDark} pair={pair} sell={sell}
                                                        setSell={setSell} coinPrice={coinPrice}
                                                        setAllTrades={setAllTrades} allTrades={allTrades} buy={buy}
                                                        setBuy={setBuy}/>
                                </div>
                            )}
                            {window.innerWidth > 1920 && (
                                <OrderRecentWrapper text={spot} isDark={isDark} pair={pair} sell={sell}
                                                    setSell={setSell} coinPrice={coinPrice}
                                                    setAllTrades={setAllTrades} allTrades={allTrades} buy={buy}
                                                    setBuy={setBuy}/>
                            )}
                        </div>
                        {window.innerWidth >= 743 && (
                            <SpotFooter isAuth={isAuth} text={spot} trading={trading}/>
                        )}
                    </div>
                ) : null}
                {(window.innerWidth < 743 && isLeftContent === 'spot') || window.innerWidth >= 743 ? (
                    <div className={styles.spotRightBox}>
                        {window.innerWidth < 743 && (
                            <SpotTotalChangerWrapper text={spot} pair={pair} changePair={changePair}/>
                        )}
                        {window.innerWidth < 743 && (
                            <button className={`${styles.showMobOrders} spotBoxBg`}
                                    onClick={() => setShowContent(!showContent)}>
                                <span>Orders</span>
                                <img style={showContent ? {transform: 'rotateZ(180deg)'} : null} src={arrow} alt=""/>
                            </button>
                        )}
                        {(window.innerWidth < 1280 && window.innerWidth >= 743) || window.innerWidth < 743 && showContent ? (
                            <div className={`${styles.spotBookWrapper} spotBg`}>
                                <OrderRecentWrapper text={spot} isDark={isDark} pair={pair} sell={sell}
                                                    setSell={setSell} coinPrice={coinPrice}
                                                    setAllTrades={setAllTrades} allTrades={allTrades} buy={buy}
                                                    setBuy={setBuy}/>
                            </div>
                        ) : null}
                        {pair ? (
                            <SpotOrderForm pair={pair} text={spot} operation={operation} setOperation={setOperation}
                                           setPopupType={setPopupType} popupType={popupType}
                                           balance={balance} setBalance={setBalance} secondBalance={secondBalance}
                                           setSecondBalance={setSecondBalance} trading={trading} setTrading={setTrading}/>
                        ) : (
                            <span className={`${styles.formSkeleton} spotBg`}/>
                        )}
                        {window.innerWidth < 743 && (
                            <SpotFooter isAuth={isAuth} text={spot} trading={trading}/>
                        )}
                    </div>
                ) : null}
                {popupType === 'transfer' && (
                    <SpotTransfer pair={pair} setPopupType={setPopupType} balance={balance} setBalance={setBalance}
                                  secondBalance={secondBalance.balance} text={spot} popupType={popupType}
                                  setSecondBalance={setSecondBalance} borrowSettings={null}/>
                )}
            </div>
        </div>
    )
}

export default Spot;