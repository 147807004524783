import React, {useState} from "react";
import styles from './Disconnect2FA.module.scss';
import {Preloader} from "../../../../Common/Preloader/Preloader";
import ReactCodeInput from "react-code-input";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {getIsDarkTheme} from "../../../../../store/selectors";
import {setDisable2FA} from "../../../../../api/api";
import {change2FA} from "../../../../../store/account-slice";

const Disconnect2FA = () => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
    const [isDisconnectFA, setIsDisconnectFA] = useState(true);
    const [code, setCode] = useState('');
    const isDarkTheme = useAppSelector(getIsDarkTheme);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const onDisconnect = () => {
        setIsDisconnectFA(!isDisconnectFA)
    }

    const handlePinChange = pinCode => {
        setCode(pinCode);
    };

    const onDisabled2FA = () => {
        setIsLoading(true);
        setDisable2FA(code).then((response) => {
            if (response?.status && response.status === 'success') {
                dispatch(change2FA(response.is_2fa));
                setCode('');
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        })
    }

    return (
        <div className={styles.disconnect2FA}>
            <p className={styles.description}>
                You can disable the additional security layer, But with it, stronger protection is provided against unauthorized access to your digital assets and personal data
            </p>
            <div className={`${styles.themeWrapper} fieldCode box`} style={isDarkTheme ? {backgroundColor: '#F7F6FE'} : {backgroundColor: '#F7F6FE'}}>
                <div className={styles.theme}>
                    <span>2FA (Activated)</span>
                </div>
                <div className={styles.changer}>
                    <div className={styles.changer__switcher}>
                        <button disabled={isDisableBtn} className={isDisconnectFA ? `${styles.switcherBoxActive} linkActive` : styles.switcherBox}
                                onClick={() => onDisconnect()}>
                            <span className={isDisconnectFA ? styles.switcherTrue : styles.switcherFalse} />
                        </button>
                    </div>
                </div>
                {isDisableBtn && (
                    <div className={`${styles.loader} loadingLocal`}>
                        <Preloader/>
                    </div>
                )}
            </div>
            {!isDisconnectFA && (
                <div className={`${styles.fieldCode} fieldCode box`}>
                    <p className={styles.fieldCode__title}>Enter the code</p>
                    <ReactCodeInput
                        id="faCode"
                        type='number'
                        fields={6}
                        onChange={handlePinChange}
                        value={code}
                    />
                </div>
            )}
            {!isDisconnectFA && (
                <div className={styles.action}>
                    <button className={'button'} disabled={code.length < 6} onClick={() => onDisabled2FA()}>
                        <span>Enable</span>
                    </button>
                </div>
            )}
            {isLoading && (
                <div className='loadingLocal' style={{position: 'fixed'}}>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default Disconnect2FA;