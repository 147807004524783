import React, {useState} from "react";
import styles from "./HeaderProfile.module.scss";
import userNoPhoto from "../../../../assets/icons/UserNoPhoto.svg";
import userNoPhotoDark from "../../../../assets/icons/UserNoPhotoDark.svg";
import HeaderProfileMenu from "./HeaderProfileMenu";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../../hooks/redux";
import {getBalances, getIsDarkTheme, getProfileInfo, getTotalBalance} from "../../../../store/selectors";


const HeaderProfile = ({hide = false}) => {
    const navigate = useNavigate();
    const {usdBalance} = useAppSelector(getTotalBalance);
    const isDark = useAppSelector(getIsDarkTheme);
    const {pathname} = useLocation();
    const [isShowMenu, setIsShowMenu] = useState(false);
    const {userName} = useAppSelector(getProfileInfo);

    return (
        <div>
            <div className={styles.profileWrapper}>
                {window.innerWidth > 1023 && (
                    <p className={`${styles.headerBalance} headerBalance`}>wallet: {usdBalance} USDT</p>
                )}
                <div className={styles.authBox}>
                    {!hide && (
                        <span>{userName}</span>
                    )}
                    <button className={!hide ? styles.usePhoto : null} style={!isDark && pathname === '/spot' ? {border: '1px solid #333F61'} : null}
                            onClick={() => window.innerWidth > 1023 ? setIsShowMenu(true) : navigate('/settings/')}>
                        <img src={!isDark && pathname === '/spot' ? userNoPhotoDark : userNoPhoto} alt=""/>
                    </button>
                </div>
                <HeaderProfileMenu isShowMenu={isShowMenu} setIsShowMenu={setIsShowMenu} />
            </div>
            {isShowMenu && (
                <button className='closer' onClick={() => setIsShowMenu(false)}/>
            )}
        </div>
    )
}

export default HeaderProfile;