import React from "react";
import styles from './HistoryListMob.module.scss';
import HistoryItemMob from "./HistoryItemMob";


const HistoryListMob = ({list}) => {

    return (
        <div className={styles.historyListMob}>
            {list && list.length > 0 && (
                list.map((item, index) => (
                    <HistoryItemMob key={index} item={item} />
                ))
            )}
        </div>
    )
}

export default HistoryListMob;