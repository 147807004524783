import React from "react";
import styles from './MainHeader.module.scss';
import {NavLink, useLocation} from "react-router-dom";
import {useAppSelector} from "../../../hooks/redux";
import {getText} from "../../../store/selectors";

const MainNavigation = () => {
    const {pathname} = useLocation();
    const {header} = useAppSelector(getText);

    const navigation = [
        {name: header.spotTrading, url: '/spot'},
        {name: header.swap, url: '/swap'},
        // {name: header.tools, url: '/tools'},
        {name: header.staking, url: '/staking'},
        // {name: header.earn, url: '/earn'},
        // {name: header.support, url: '/support'},
        // {name: header.buyCrypto, url: '/buy'},
    ];

    return (
        <div className={styles.navigation}>
            {navigation && (
                navigation.map((item, index) =>
                    <NavLink key={index}
                             className={pathname === item.url ? styles.navigation__linkActive : styles.navigation__link}
                             to={item.url}>{item.name}</NavLink>
                )
            )}
        </div>
    )
}

export default MainNavigation;