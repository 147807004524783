import React, {useEffect, useState} from "react";
import styles from './Set2FAAuth.module.scss';
import FASteps from "./2faSteps";
import CodeField from "./CodeField";
import Notification from "../../../Common/Notification/Notification";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from '../../../../assets/icons/copy.svg';
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {getIsDarkTheme, getProfileInfo} from "../../../../store/selectors";
import {get2FACode, setEnable2FA} from "../../../../api/api";
import {Preloader} from "../../../Common/Preloader/Preloader";
import Disconnect2FA from "./Disconnect2FA/Disconect2FA";
import {change2FA} from "../../../../store/account-slice";

const Set2FAAuth = ({text}) => {
    const [qrCode, setQrCode] = useState(null);
    const [qrImg, setQrImg] = useState(null);
    const isDark = useAppSelector(getIsDarkTheme);
    const [code, setCode] = useState('');
    const [isShowCode, setIsShowCode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const userInfo = useAppSelector(getProfileInfo);
    const dispatch = useAppDispatch();

    const onSubmit = () => {
        if (window.innerWidth > 1023) {
            setIsLoading(true);
            if (code?.length === 6) {
                setEnable2FA(code).then((response) => {
                    if (response?.status && response.status === 'success' && response.is_2fa) {
                        dispatch(change2FA(response.is_2fa));
                        setCode('');
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
            }
        } else {
            setIsShowCode(true)
        }
    }

    useEffect(() => {
        if (!qrCode) {
            setIsLoading(true);
            get2FACode().then((result) => {
                if (result && result.status) {
                    setQrCode(result.secret);
                    setQrImg(result.qrCodeUrl);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
        }
    }, []);

    return (
        <div className={styles.set2FAAuth}>
            {window.innerWidth > 1023 && (
                <h2>
                    {text.faAuth}
                    {userInfo.twoFactor && (
                        <span>Connected</span>
                    )}
                </h2>

            )}
            {!userInfo.twoFactor && (
                <div className={styles.info}>
                    <FASteps text={text} />
                    <div className={styles.qrCode}>
                        {qrImg && (
                            <img className={styles.qrCode__qrImage} src={`data:image/png;base64,${qrImg}`} alt="QR Code" />
                        )}
                        {qrCode && (
                            <p style={isDark ? {border: '1px solid #FFFFFF80'} : {border: '1px solid #C9CEDE'}}>
                                {qrCode}
                                {window.innerWidth < 1024 && (
                                    <CopyToClipboard text={qrCode}>
                                        <button><img src={copyIcon} alt=""/></button>
                                    </CopyToClipboard>
                                )}
                            </p>
                        )}
                    </div>
                </div>
            )}
            {!userInfo.twoFactor && (
                <CodeField code={code} setCode={setCode} isShowCode={isShowCode} setIsShowCode={setIsShowCode} text={text} />
            )}

            {window.innerWidth >= 1024 && !userInfo.twoFactor && (
                <div className={styles.info__notifications}>
                    <Notification text={text.notification} />
                </div>

            )}
            {!userInfo.twoFactor && (
                <div className={styles.action}>
                    <button disabled={code.length < 6} className={'customBtn'} onClick={() => onSubmit()}>
                        <span>{text.confirm}</span>
                    </button>
                </div>
            )}
            {userInfo.twoFactor && (
                <Disconnect2FA />
            )}
            {isLoading && (
                <div className='loadingLocal' style={{position: 'fixed'}}>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default Set2FAAuth;